import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import AniLink from "gatsby-plugin-transition-link/AniLink"
import blogstyles from "./blog.module.scss"
import baseStyles from "./base.module.scss"
import Head from "../components/head"
import Layout from "../components/layout"

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(sort: { fields: publishedDate, order: DESC }) {
        edges {
          node {
            title
            slug
            publishedDate(formatString: "MMMM Do, YYYY")
            headerImage {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Head title="Blog" />
      <Layout>
        <div className={baseStyles.wrapper}>
          <div className={baseStyles.container}>
            <ul>
              <li id="scroll" className={baseStyles.listItem}>
                <div className={blogstyles.splash}>
                  <div className={blogstyles.aligner}>
                    <h1>Blog</h1>
                    <h2>
                      I love programming.
                      <br />
                      Let's talk tech. <br />
                      <br />
                    </h2>
                  </div>
                </div>
              </li>
              <li className={baseStyles.listItem}>
                <ul className={blogstyles.posts}>
                  {data.allContentfulBlogPost.edges.map(edge => (
                    <li className={blogstyles.post} key={edge.node.slug}>
                      <AniLink
                        to={`/blog/${edge.node.slug}`}
                        direction="left"
                        fade
                      >
                        <h3>{edge.node.title}</h3>
                        <p>{edge.node.publishedDate}</p>
                        <img
                          src={edge.node.headerImage.file.url}
                          alt={edge.node.title}
                          className={blogstyles.image}
                        />
                      </AniLink>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default BlogPage
